#sobre-mi{
    background-color: white;
}
#sobre-mi a{
    display: inline;
    color: inherit;
    font-weight: 700;
}
#link-externo{
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    background-image: url("../../../public/img/link-externo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
#conocimientos-lista{
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}
#conocimientos-lista > div{
    width: 50%;
    max-width: 360px ;
}
#conocimientos-lista > div > div{
    width: 100%;
    margin-bottom: 0.8rem;
}
.conocimiento-nombre{
    width: 45%;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 600;
}
.progress-bar{    
    background-color: lightgray;
    width: 70%;
    height: 1.2rem;
}
.progress-bar > div{
    height: 1.2rem;
    background-color: #6FF266;
}
.nivel-90{
    width: 95%;
}
.nivel-alto{
    width: 80%;
}
.nivel-medio{
    width: 60%;
}
.nivel-bajo{
    width: 40%;
}
@media screen and (min-width: 576px){
    .progress-bar{
        width: 100%;
    }
}
@media screen and (min-width: 768px){
    #sobre-mi #about-texto{
        width: calc(100% - 420px);
        margin-right: 90px;
    }
    #sobre-mi #imagen-martin{
        width: 320px;
    }
    #sobre-mi div:has(img) img{
        width: 100%;
        max-width: 250px;
    }
}
@media screen and (min-width: 1024px){
    #conocimientos-lista > div{
        max-width: 450px ;
    }
}