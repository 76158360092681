#servicios{
    background-color: white;
}
#servicios div#que-hago-contenedor > p{
    font-size: 1.1rem;
}
#servicios div#que-hago-contenedor ul{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
#servicios div#que-hago-contenedor ul li{
    margin-bottom: 3rem;
}
#servicios div#que-hago-contenedor ul li h3{
    font-weight: 600;
    font-size: 1.4rem;
}
#servicios div#que-hago-contenedor ul li img{
    max-width: 80px;
    order: -1;
}
#servicios div#que-hago-contenedor ul li p{
    max-width: 310px;
}
@media screen and (min-width: 576px){
    #servicios div#que-hago-contenedor ul li{
        max-width: 35%;
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (min-width: 768px){
    #servicios div#que-hago-contenedor > p{
        font-size: 1.2rem;
    }
    #servicios-mantenimento div:first-child{
        width: 50%;
        margin-right: 20px;
    }
    #servicios-mantenimento div:last-child{
        width: 50%;
    }
    #servicios-mantenimento div img{
        width: 100%;
        max-width: 550px;
    }
}
@media screen and (min-width: 992px){
    #servicios div#que-hago-contenedor ul li{
        max-width: 33%;
        
    }
    #servicios div#que-hago-contenedor ul li p{
        padding: 0 2rem;
    }
}
@media screen and (min-width: 1200px){
    #servicios div#que-hago-contenedor ul li img{
        max-width: 100px;
    }
}