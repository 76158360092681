nav {
	background-color: var(--bs-primary);
}
.navbar [type="button"]:hover,
.navbar [type="button"]:focus {
	border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
	background-color: transparent;
}
.navbar-nav {
	align-items: flex-end;
}
.navbar-nav a {
	font-family: var(--fuente-textos);
	color: whitesmoke;
	margin-bottom: 1rem;
}
.navbar-toggler-icon {
	background-image: url("/public/img/icons8-menú-30.png");
}
#logo {
	font-size: 1.5rem;
}
@media screen and (min-width: 400px) {
	#logo {
		font-size: 1.3em;
	}
}
@media screen and (min-width: 992px) {
	.navbar-nav a {
		margin-bottom: 0;
	}
}
