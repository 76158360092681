#trabajos div ul li{
    max-width: 500px;
    max-height: 750px;
}
#trabajos div ul li a{
    width: 100%;
}
#trabajos div.contenedor.centrado{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#trabajos div.contenedor.centrado h2{
    align-self: flex-start;
}