#section-banner h2,
#section-banner p {
	color: white;
}
#background-video {
	display: none;
}
body {
	background: url("/public/img/poster-video.webp");
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}
.carousel-item {
	position: relative;
	height: calc(100vh - 82px);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.carousel-caption {
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -30%);
	text-align: left;
	width: 75%;
	background-color: rgba(0, 0, 0, 0.7);
	padding: 1rem;
	text-align: center;
}
.carousel-caption > h2 {
	font-size: 8vw;
	font-weight: 700;
	margin-bottom: 1rem !important;
}
.carousel-caption > p {
	font-family: "Ubuntu", sans-serif;
	font-size: 6vw;
	margin-bottom: 0 !important;
}
#carousel-1 .carousel-caption {
	height: 52vw;
}
#carousel-2 .carousel-caption {
	height: 60vw;
}
#carousel-3 .carousel-caption {
	height: 60vw;
}
#carousel-4 .carousel-caption {
	height: 60vw;
}
#carouselIndicators button {
	background-color: transparent;
	color: transparent;
	border: none;
}
.carousel-control-next,
.carousel-control-prev {
	top: -30px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
	background-size: 186% 186%;
	opacity: 0.7;
}
@media screen and (min-width: 400px) {
	.carousel-item {
		height: calc(100vh - 70px);
	}
}
@media screen and (min-width: 460px) {
	.carousel-caption {
		top: 50%;
		transform: translate(-50%, -50%);
	}
	#carousel-3 .carousel-caption {
		/*height: 50%;*/
	}
}
@media screen and (min-width: 576px) {
	.carousel-caption > h2 {
		font-size: 2.2rem;
	}
	.carousel-caption > p {
		font-size: 1.2rem;
	}
	#carousel-1 .carousel-caption {
		height: 140px;
	}
	#carousel-2 .carousel-caption {
		height: 180px;
	}
	#carousel-3 .carousel-caption {
		height: 180px;
	}
	#carousel-4 .carousel-caption {
		height: 160px;
	}
}
@media screen and (min-width: 582px) {
	.carousel-caption {
		width: max-content;
	}
	#carousel-1 .carousel-caption {
		height: 140px;
	}
	#carousel-2 .carousel-caption {
		height: 140px;
	}
	#carousel-3 .carousel-caption {
		height: 140px;
	}
	#carousel-4 .carousel-caption {
		height: 140px;
	}
}
@media screen and (min-width: 768px) {
	#background-video {
		background-image: none;
		display: block;
		height: 100%;
		width: 100vw;
		object-fit: cover;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: -1;
	}
	.carousel-item {
		height: calc(100vh - 80px);
	}
	.carousel-control-next,
	.carousel-control-prev {
		top: 0;
	}
	.carousel-caption {
		top: 50%;
		width: 75%;
		transform: translate(-50%, -50%);
	}
	#carousel-1 .carousel-caption {
		height: 140px;
	}
	#carousel-2 .carousel-caption {
		height: 230px;
	}
	#carousel-3 .carousel-caption {
		height: 230px;
	}
	#carousel-4 .carousel-caption {
		height: 180px;
	}
	.carousel-caption > h2 {
		font-size: 3rem;
	}
	.carousel-caption > p {
		font-size: 1.7rem;
	}
}
@media screen and (min-width: 900px) {
	.carousel-caption {
		width: max-content !important;
		padding: 1rem;
	}
	#carousel-1 .carousel-caption {
		height: 140px;
	}
	#carousel-2 .carousel-caption {
		height: 180px;
	}
	#carousel-3 .carousel-caption {
		height: 180px;
	}
	#carousel-4 .carousel-caption {
		height: 140px;
	}
}
@media screen and (min-width: 992px) {
	/*.carousel-caption > h2 {
		font-size: 3.5rem;
	}
	.carousel-caption > p {
		font-size: 1.8rem;
	}*/
	#carousel-1 .carousel-caption {
		height: 150px;
	}
	#carousel-2 .carousel-caption {
		height: 150px;
	}
	#carousel-3 .carousel-caption {
		height: 150px;
	}
	#carousel-4 .carousel-caption {
		height: 150px;
	}
}
@media screen and (min-width: 1200px) {
	.carousel-caption > h2 {
		font-size: 3.7rem;
	}
	.carousel-caption > p {
		font-size: 1.7rem;
	}
	.carousel-caption {
		height: 170px !important;
	}
}
