h1 span{
    line-height: 1;
    padding: 0;
}
h1 span:first-child {
	color: whitesmoke;
}
h1 span:last-child {
	color: var(--bs-light);
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
}