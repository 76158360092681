.img-preview{
    max-width: 500px;
    min-width: 200px;
    max-height: 750px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);    
}

@media screen and (min-width: 992px){
    .img-preview{
        opacity: 0.8;
    }
    .img-preview:hover{
        opacity: 1;
    }
}