#trabajo-detalle {
	background-color: white;
}
#cliente-titulo {
	border-bottom: 1px solid var(--bs-blue);
	padding-bottom: 1rem;
	margin-bottom: 2rem;
	line-height: 1.5;
}
#trabajo-detalle img {
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 1);
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 1);
}
#btn-link-sitio-cliente {
	background-color: var(--bs-blue);
	width: max-content;
	color: whitesmoke;
	padding: 0.3rem 1rem;
	border-radius: 5px;
}
.info-box {
	width: 100%;
	max-width: 500px;
}
#trabajo-detalle ul li {
	list-style-type: square;
	list-style-position: inside;
}
#trabajo-detalle ul li::marker {
	color: var(--bs-blue);
}
#trabajo-detalle ul li a {
	width: 100%;
	text-align: center;
}
#titulo-error {
	background-image: url("/public/img/404computer.png");
	background-repeat: no-repeat;
	background-position: top center;
	padding-top: 250px;
	background-size: 200px;
}
@media screen and (min-width: 768px) {
	.info-box {
		width: 45%;
	}
}
@media screen and (min-width: 992px) {
	#trabajo-detalle img {
		max-width: 450px;
		height: auto;
	}
}
@media screen and (min-width: 1100px) {
	#trabajo-detalle img {
		max-width: 500px;
		height: auto;
	}
}
