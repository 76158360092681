#contacto{
    background-color: lightcyan;
}
#caja-form{
    width: 100%;
}
#help-obligatorios{
    font-size: 0.8rem;
    margin-top: 2rem;
}
#help-atencion{
    color: red;
    margin-top: 1rem;
}
.campo-invalido{
    outline: 1px solid red;
}
small.mensaje-campo-vacio{
    color: red !important;
    font-size: 0.8rem;
}
form , #caja-mensajes , #caja-email{
    max-width: 660px;
}
form > div ,  #caja-mensajes , #caja-email{
    width: 100%;
}
form textarea{
    resize: vertical;
}
.asterisco-form{
    color: tomato;
    font-weight: 400;
}
form input , form textarea{
    margin: 0;
}
form label{
    font-family: "Montserrat" , sans-serif;
}
#mensaje-success{
    font-size: 1.2rem;
    background-color: #6FF266;
    padding: 1rem 0;
}
#mensaje-error{
    background-color: tomato;
    color: whitesmoke;
    font-size: 1.2rem;
    padding: 0.5rem;
    margin: 0;
}
#mensaje-error a{
    color: var(--bs-body-color);
    text-decoration: underline;
}
#btn-enviar-mail{
    background-color: var(--bs-primary);
    color: whitesmoke;
    padding: 0 0.5rem;
    border-radius: 5px;
}
#btn-enviar-mail:hover{
    background-color: transparent;
    color: var(--bs-primary);
    outline: 2px solid var(--bs-primary);
}
@media screnn and (min-width: 768px){
    #caja-form{
        width: 70%;
    }   
}