#myBtn {
	display: block;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 2000;
	background-image: url("/public/img/flecha.png");
	background-size: contain;
	background-repeat: no-repeat;
	width: 45px;
	height: 45px;
	cursor: pointer;
	border: none;
	background-color: transparent;
	
}
#myBtn:hover{
	animation: movebtn 3s ease-in-out infinite;
  	transition: all .5s ease-in-out;
}