:root{
	--fuente-titulos: 'Red Hat Display', Verdana , sans-serif;
	--fuente-textos: 'Ubuntu', Arial , sans-serif;
}
* {
	font-family: var(--fuente-textos);
	box-sizing: border-box;
	margin: 0;
}
*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
html,
body {
	height: 100%;
	scroll-behavior: smooth;
	overflow-x: hidden;
}
body {
	min-width: 300px;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg,
a {
	display: block;
	max-width: 100%;
}
input,
button,
textarea,
select {
	font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--fuente-titulos);
}
p{
	margin-bottom: 0.8rem !important;
}
main {
	padding: 3em 0;
}
li {
	list-style: none;
}
a,
a:link,
a:active,
a:visited,
a:hover {
	text-decoration: none;
}
span {
	color: inherit;
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
}
.fontCero {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}
.contenedor {
	max-width: 1600px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 0 1em;
}
.fondo-color{
	background-color: lightcyan;
}
section:not(#section-banner):not(#servicios-mantenimento),
main {
	padding: 3em 0;
}
h2:not(.carousel-inner h2):not(#titulo-error):not(#titulo-404)::before{
	content: "|";
	color: var(--bs-primary);
	font-weight: 700;
	margin-right: 10px;
}
h2{
	font-weight: 600 !important;
}
h2 , h3{
	margin-bottom: 2rem !important;
}
h3:not(#servicios ul li h3){
	font-weight: 600;
}
.texto-error{
    font-size: 1.3rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    line-height: 1.5;
}
.btn-volver{
    color: white;
    background-color: var(--bs-blue);
    border: 1px solid var(--bs-blue);
    padding: 1rem;
    border-radius: 5px;
	margin: 2rem 1rem;
}
.btn-volver:hover{
	color: white;
    background-color: var(--bs-blue);
    border: 1px solid var(--bs-blue);
}
@media screen and (min-width: 1024px) {
	.contenedor {
		padding: 0 2em;
	}
}
@media screen and (min-width: 1200px) {
	.contenedor {
		padding: 0 3em;
	}
}
@media screen and (min-width: 1600px) {
	.contenedor {
		padding: 0 4em;
	}
}
