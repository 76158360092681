#metodologia{
	background-color: #ededed;
	padding: 1rem !important;
}
#pasos ol {
	width: 100%;
	margin: 4rem auto;
}
#pasos ol li {
	max-width: 100%;
	margin-bottom: 2rem;
	/*align-items: start !important;*/
}
#pasos ol li h4{
	margin-bottom: 2rem;
	font-size: 1.4rem;
	font-weight: 600;
}
#pasos ol li h4 span{
	font-family: var(--textos-titulos);
	font-size: 1rem;
	font-weight: 700;
	background-color: var(--bs-primary);
	color: whitesmoke;
	border-radius: 50%;
	border: 1px solid var(--bs-primary);
	padding: 0.5rem 1rem;
    margin-right: 1rem;
}
#link-ancla{
	display: inline;
	text-decoration: underline;
}
#link-ancla:hover{
	color: var(--bs-body-color);
}
.btn-popover,
.btn-popover:hover,
.btn-popover:focus,
.btn-popover:active {
	background: transparent;
	color: var(--bs-body-color);
	font-weight: 600;
	border: none;
	box-shadow: none;
	padding: 0;
	margin: 0;
}
.popover-header {
	font-weight: 600;
	color: whitesmoke;
	border: none;
	border-radius: 0;
	background-color: var(--bs-primary);
}
.popover-body {
	padding-top: 0;
}
#metodologia-extra h5 , #metodologia-extra p{
	color: var(--bs-primary);
	
}
#metodologia-extra h5{
	font-weight: 600;
}
@media screen and (min-width: 768px) {
	#pasos ol li {
		max-width: 45%;
	}
}
@media screen and (min-width: 1024px) {
	#pasos ol {
		width: 90%;
	}
}
