footer{
    background-color: var(--bs-primary);
    color: whitesmoke;
    border-top: 2px solid var(--bs-primary);
    padding: 2rem 0 1rem 0;
}
#footer-contacto{
    margin: 2rem 0;
}
#footer-contacto a {
    color: inherit;
    font-size: 1.2rem;
}
#footer-pie{
    margin-top: 3rem;
    font-size: 0.9rem;
    
}
#footer-pie p{
    text-align: center;
}
#footer-pie p:last-child{
    font-size: 0.8rem;
}
#footer-pie a{
    color: inherit;
    display: inline-block;
}
@media screen and (min-width: 800px){
    #footer-pie p:nth-child(1){
        text-align: left;
    }
    #footer-pie p:nth-child(2){
        text-align: right;
    }
}