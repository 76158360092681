#footer-navbar li:not(:last-child){
    margin-bottom: 1rem;
}
#footer-navbar a{
    color: whitesmoke;
    text-align: center;
}
@media screen and (min-width: 576px){
    #footer-navbar li:not(:last-child){
        margin-right: 1rem;
        margin-bottom: 0;
    }
}